
/*BETTERBONUS CSS*/

.cas-fs-sect .cas-procon-section{
	border-top-right-radius:5px;
	border-top-left-radius:5px;
}
.cas-inner-pd-tb{
	    padding: 10px 15px;
}
.cas-fs-sect .cas-procon-section {
    display: flex;
    background-color: #dfe6e9;
    flex-direction: column;
}
.cas-fs-sect .cas-claim-button a{
	margin-bottom: 14px;
	width:100%;
}
.cas-fs-sect .cas-claim-button .glow{
	left: 100px;
    width: 50%;
}
.cas-procon-container {
        display: flex;
    width: 100%;
    flex-direction: column;
}
.cas-procon-container p{
	font-weight:400;
	margin:0;
	margin-bottom: 10px;
}
.cas-procon-container h4{
	margin-bottom:8px;
}
.cas-ss-img{
	margin-top:6px;
}
.cas-fs-sect .cas-ss-img{
	margin-bottom:14px;
}
.cas-procon-table {
	
    flex: 1;
    border-collapse: collapse;
}

.cas-procon-table th,
.cas-procon-table td {
	width:50%;
}

.cas-procon-table{
	border-radius:5px;
    width: 100%;
    border-collapse: collapse;

    font-size: 14px;
    text-align: left;
	border:none;
}
.cas-procon-table th{
 border:none;
}
.cas-procon-table td{
 border:none;
}
.cas-bonus-table{
width: 100%;
  background: #00002B;
	box-shadow: 0 2px 2px 0 dimgray;
	border-radius:5px;
}
.cas-bonus-table th {
    background-color: #011263;
	color:white;
    padding: 8px;
    font-weight: bold;
}
.cas-pro-text {
    width: 50%;
	padding-top:0;
		padding-bottom:0;
}
.cas-con-text {
    width: 50%;
	padding-top:0;
		padding-bottom:0;
}
.cas-pro-row {
    width: 50%;
}
.cas-con-row {
    width: 50%;
}
.cas-bonus-table td {
	background-color:#dfe6e8;
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: middle;
    width: 80%;
}
.cas-bonus-table td ul {
    margin: 0;
    padding-left: 20px;
}
.cas-bonus-table caption {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
}
.cas-slot-review-table{
	border: 3px solid /*#00002B*/#00002B;
	box-shadow: 0 2px 2px 0 dimgray;
	border-radius:5px;
}
.cas-slot-review-table th {
    background-color: #011263;
	color:white;
    border: 1px solid #00002B;
    padding: 8px;
    font-weight: bold;
}
.cas-pro-text {
    width: 50%;
	padding-top:0;
		padding-bottom:0;
}
.cas-con-text {
    width: 50%;
	padding-top:0;
		padding-bottom:0;
}
.cas-pro-row {
    width: 50%;
}
.cas-con-row {
    width: 50%;
}
.cas-slot-review-table td {
	background-color:#dfe6e8;
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: middle;
}
.cas-slot-review-table td ul {
    margin: 0;
    padding-left: 20px;
}
.cas-slot-review-table caption {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
}
.cas-cont-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.cas-cont-wrapper-child{
	border: 3px solid /*#00002B*/#001262;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
	box-shadow: 0 2px 2px 0 dimgray;
	border-radius:5px;
}
.cas-procon-section{
	display:flex;
	background-color:#dfe6e9;
}
.cas-procon-section .cas-cont-box-50{
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cas-procon-section ul{
	padding:0;
	margin:0;
}
.cas-con-text{
	font-size: 18px;
	font-weight: bolder;
	color:red;
}
.cas-pro-text{
	font-size: 18px;
	font-weight: bolder;
	color:green;
}
.cas-con-row{
	font-weight: 700;
    font-size: 16px;
    line-height: 1.25em;
    color: #00002B;
	margin-bottom:10px;
}
.cas-pro-row{
	font-weight: 700;
    font-size: 16px;
    line-height: 1.25em;
    color: #00002B;
	margin-bottom:10px;
}
.cas-pro-row::before{
	content:'✅ ';
}
.cas-con-row::before{
	content:'❌ ';
}
.cas-procon-section ul li{
	list-style:none;
}
.cas-relative p{
    padding: 0;
    margin:0 0 5px 0;
}
.cas-bonus-title-text{
    padding:0 !important;
    margin:0 !important;
}
.cas-cont-box-100{
    width: 100%;
}
.cas-cont-box-50 {
    width: 50%;
}
.cas-cont-box-25 {
    width: 25%;
}
.cas-cont-box-60 {
    width: 54%;
}
.cas-cont-box-15 {
    width: 21%;
}
.cas-inner-pd{
    padding: 20px 25px;
}
.cas-inner-pd-spec{
    padding: 5px 5px;
}
.cas-left-cont{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
}
.cas-img-container{
    max-width: 150px;
    display: inline-block;
    position:relative;
}
.cas-pos-number{
  width: 50px;
  background: gold;
  height: 50px;
  display: flex;
  position: absolute;
  left: -30px;
  top: -30px;
  font-size: 85%;
  z-index: 15;
  border-radius: 50%;
  color: #000;
  font-weight: bolder !important;
  align-items: center;
  justify-content: center;
}
.numbersubmitform{
  width: 80%;
    display: flex;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bolder;
    height: auto;
    padding: 0;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border: none;
}
.termstextsubmitform{
  width: 81%;
    display: flex;
    text-align: left;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bolder;
    height: auto;
    padding: 5px 10px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border: none;
}
.termslinktextsubmitform{
  width: 40%;
    display: flex;
    text-align: left;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bolder;
    height: auto;
    padding: 5px 10px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border: none;
}
.termsbuttonstyle{
  text-transform: uppercase;
  outline: 0;
  border-radius: 5px;
  background: var(--primary-error-color);
  /* width: 100%; */
  border: 0;
  padding: 5px;
  margin: 5px;
  color: white;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: bolder;
}
.termsbuttonstyle.selected{
  background: var(--primary-profile-color);
}
.termsbuttonstyleadd{
  text-transform: uppercase;
  outline: 0;
  border-radius: 5px;
  background: var(--primary-success-color);
  /* width: 100%; */
  border: 0;
  padding: 5px;
  margin: 5px;
  color: white;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: bolder;
}
.termsbuttonstyleremove{
    text-transform: uppercase;
    outline: 0;
    border-radius: 5px;
    background: var(--primary-error-color);
    /* width: 100%; */
    border: 0;
    padding: 5px;
    margin: 5px;
    color: white;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
    font-weight: bolder;
  }
.termstextlinkparent{
  display: flex;
}
.termstextlinkparent input{
  padding: 5px;
  margin: 5px;
}
.cas-img-container a{
    height: fit-content;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    margin-bottom: 5px;
	position: relative;
	z-index:99;
}
.cas-img{
    height: 50px;
		width:150px;
    border-radius: 5px;
	outline: 3px solid #001262;
	/*padding: 3px;*/
	/*-webkit-animation: pulse 3s linear infinite;-moz-animation: pulse 3s linear infinite;*/
}
.cas-cont{
    margin: 0 15px;
    border-radius: 5px 5px 0 0;
    background-color: #ecf0f1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
}
.cas-relative{
    position: relative;
}
.cas-accepted-txt{
    padding: 5px 0 0;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.45em;
    color: #7f8c8d;
}
.cas-acc-text{
    color: white;
	    display: flex;
	align-items: center;
    font-size: 10px;
}
.cas-acc-text svg{
	margin-right:3px;
}
.cas-acc-text-btn{
    color: black;
	font-weight:bolder;
    font-size: 10px;
}
.cas-terms-section-text{
	display:block;
	align-items:center;
}
.cas-terms-section-text a{
	color:white;
}
.cas-left{
    width: 25%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00002B;
    color:white;
}
.cas-center{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.cas-center-items{
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.45em;
    color: #7f8c8d;
    display: flex;
    flex-direction: column;
}
.cas-bonus-text{
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25em;
    color: #00002B;
    padding-bottom: 5px;
}
.cas-terms-text{
    color: #00002B;
    font-style: italic;
	font-weight:bolder;
}
.cas-bonus-title-text{
    font-weight: 700;
    font-size: 18px;
    line-height: 2.5rem;
    color: #011263;
}
.cas-right{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.cas-right-items{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cas-bonus-score{
    display: inline-block;
    min-width: 100px;
}
.cas-text-center{
    text-align: center;
}
.cas-rating{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.75em;
    line-height: 25px;
}
.cas-rating-star-wrap{
    width: 25px;
    height: 25px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
}
.cas-rating-star-background{
    background-color: #ffd32a;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.3;
}
.cas-rating-star-icon{
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cas-super-rate{
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: white;
}
@media only screen and (max-width: 1024px){
	.cas-procon-cont{
	flex-direction: column !important;
}
}
.cas-procon-cont{
	display:flex;
	flex-direction: row;
}
.cas-minor-rate{
    font-size:14px;
}
.cas-claim-button{
    display: flex;
    flex-direction: column;
}
.cas-claim-btn{
	z-index: 1;
    display: flex;
    flex-direction: column;
}
.cas-claim-btn a{
	text-decoration:none;
    border-radius: 5px;
    padding: 15px 33px;
    color: #ffffff;
    background-color: #0ecc5d;
    box-shadow: 0px 5px 15px 0px rgba(14,204,93, 0.55);
    border: 3px solid #41811d;
    font-size: 1em;
    line-height: 1.25em;
    display: inline-block;
    font-weight: bolder;
}
.cas-bonus-score-text{
    color: white;
    font-size: 14px;
    line-height: 1.25em;
    margin-bottom: 2px;
}
.cas-bonus-score-text span{
    font-weight: bolder;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
/*RIBBON CSS*/
.ribbon-wrapper {
	overflow:hidden;
    display: flex;
    position: relative;
    z-index: 998;
    margin-left: auto;
    margin-right: auto;
}
.ribbon-front {
    box-shadow: 0 2px 2px 0 dimgray;
    background: #00002B;
    color:white;
    width: 240px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 2; font:20px/50px bold Verdana, Geneva, sans-serif; text-align:center;text-shadow: 0px 1px 2px #1a1ac0;
}

.ribbon-front,
.ribbon-back-left,
.ribbon-back-right
{
    -moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
    -khtml-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
    -webkit-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
    -o-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
}

.ribbon-edge-topleft,
.ribbon-edge-topright,
.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
    position: absolute;
    z-index: 1;
    border-style:solid;
    height:0px;
    width:0px;
}

.ribbon-edge-topleft,
.ribbon-edge-topright {
}

.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
    top: 50px;
}

.ribbon-edge-topleft,
.ribbon-edge-bottomleft {
    left: -10px;
    border-color: transparent #1a1ac0 transparent transparent;
}

.ribbon-edge-topleft {
    top: -5px;
    border-width: 5px 10px 0 0;
}
.ribbon-edge-bottomleft {
    border-width: 0 10px 0px 0;
}

.ribbon-edge-topright,
.ribbon-edge-bottomright {
    left: 220px;
    border-color: transparent transparent transparent #1a1ac0;
}

.ribbon-edge-topright {
    top: 0px;
    border-width: 0px 0 0 10px;
}
.ribbon-edge-bottomright {
    border-width: 0 0 5px 10px;
}

@-webkit-keyframes pulse {
    0% { box-shadow: 0 0 5px 1px lightskyblue; }
    50% { box-shadow: 0 0 5px 1px gold; }
    100%{ box-shadow: 0 0 5px 1px lightskyblue; }
}
@keyframes pulse {
    0% { box-shadow: 0 0 5px 1px lightskyblue; }
    50% { box-shadow: 0 0 5px 1px gold; }
    100%{ box-shadow: 0 0 5px 1px lightskyblue; }
}

@-webkit-keyframes flow {
    0% { left:-20px;opacity: 0;}
    50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}
@keyframes flow {
    0% { left:-20px;opacity: 0;}
    50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}

.glow{ 
top:0;
background: rgb(255,255,255); width:40px; height:100%; z-index:999; position:absolute;-webkit-animation: flow 3s linear infinite;-moz-animation: flow 3s linear infinite;-webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* IE10+ */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */ border-left:1px solid #fff;}
.primary-menu ul li{
	position:relative;
}
.main-navigation .main-nav ul li[class*="current-menu-"] > a {
    color: var(--accent) !important;
}

@media only screen and (min-width:768px){
	.cas-claim-btn a:hover{
		color:white;
		text-decoration:underline;
	}
	.new > a{
		overflow:hidden;
	}
	.blue > a:before {
    content: "NEW";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 4px;
		font-weight:bolder;
    right: 24px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowzblue 3s linear infinite;-moz-animation: flowzblue 3s linear infinite;
}
	.green > a:before {
    content: "TOP";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 4px;
		font-weight:bolder;
    right: 27px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowzgreen 3s linear infinite;-moz-animation: flowzgreen 3s linear infinite;
}
	.red > a:before {
    content: "HOT";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 4px;
		font-weight:bolder;
    right: 27px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowzred 3s linear infinite;-moz-animation: flowzred 3s linear infinite;
}
	.new > a:before {
    content: "VIP";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 4px;
		font-weight:bolder;
    right: 29px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowz 3s linear infinite;-moz-animation: flowz 3s linear infinite;
}
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .cas-left{
        width: 50% !important;
        order: 1;
    }
    .cas-right{
        border-radius: 0 5px 0 0;
        background-color: #dfe6e9;
        width: 50% !important;
        order: 2;
    }
    .cas-center{
        width: 100% !important;
        order: 3;
    }
}
/* Default table layout */
.cas-pro-text, .cas-con-text, .cas-pro-row, .cas-con-row {
    display: table-cell;
}

/* Counter for the labels */
.cas-pro-row::before, .cas-con-row::before {
    counter-increment: section; 
}
@media only screen and (max-width: 767px) {
	

	
	
	.blue > a:before {
    content: "NEW";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 23px;
    right: -10px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowzblue 3s linear infinite;-moz-animation: flowzblue 3s linear infinite;
}
	.green > a:before {
    content: "TOP";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 23px;
    right: -10px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowzgreen 3s linear infinite;-moz-animation: flowzgreen 3s linear infinite;
}
	.red > a:before {
    content: "HOT";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 23px;
    right: -10px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowzred 3s linear infinite;-moz-animation: flowzred 3s linear infinite;
}
	.new > a:before {
    content: "VIP";
    font-weight: 700;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    color: black;
    background-color: gold;
    display: inline-block;
    top: 23px;
    right: -10px;
    padding: 2px 7px;
    border-radius: 5px;
		-webkit-animation: flowz 3s linear infinite;-moz-animation: flowz 3s linear infinite;
}
	.cas-left-cont {
    width: 100%;
	}
    .cas-left{
        width: 100% !important;
        order: 1;
        padding: 20px 30px;
        margin-bottom: 0;
    }
	.cas-relative p{
		font-size:150%;
	}
	.cas-terms-text{
		font-size:110%!important;
		font-weight:bolder;
	}
    .cas-right{
        margin-top: 20px;
			padding: 10px 20px 5px 20px;
    }
	.cas-procon-section .cas-inner-pd{
        padding-top: 5px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }
    .cas-inner-pd{
        padding-top: 10px;
        padding-right: 30px;
        padding-bottom: 0;
        padding-left: 30px;
    }
    .cas-right{
        border-radius: 0 0 5px 5px;
        background-color: #dfe6e9;
        width: 100% !important;
        order: 2;
        
    }
    .cas-center{
        width: 100% !important;
        order: 2;
        flex-direction: column;
    }
    .cas-right-items {
        width: 100%;
        justify-content: center;
        flex-direction: row;
    }
    .cas-bonus-score{
        margin-right:15px;
        width: 50%;
    }
    .cas-claim-button{
        width: 50%;
    }
    .cas-claim-btn a {
        width: 75%;
    border-radius: 5px;
    padding: 18px 10px;
    }
    .cas-claim-btn {
        display: flex;
        justify-content: center;
    }
    .cas-cont-box-50{
        width: 100%;
    }
	.cas-claim-button {
    width: 100%;
}
	@-webkit-keyframes flow {
    0% { left:-20px;opacity: 0;}
    50% {left:150px;opacity: 0.3;}
    100%{ left:280px;opacity: 0;}
}
@keyframes flow {
    0% { left:-20px;opacity: 0;}
    50% {left:150px;opacity: 0.3;}
    100%{ left:280px;opacity: 0;}
}
	.cas-left .cas-img-cont-wrap{
		width: 100% !important;
		padding: 0;
	}
	.cas-img-container {
    /* max-width: 150px; */
    width: 75%;
    display: inline-block;
    position: relative;
}
}
.cas-terms-section{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
  display: flex;
    align-items: center;
    justify-content: left;
    background: #011263;
    padding: 5px 5px;
}
.cas-terms-section-text{
	margin-left:5px;
	color:white;
	font-size:12px;
}
/*1025*/
/*768*/
@-webkit-keyframes flowz {
    0% {background-color:gold;}
    25% {background-color:#fd9702;}
	50% {background-color:#fd6d02;}
	75% {background-color:#fd9702;}
    100%{background-color:gold;}
}
@keyframes flowz {
    0% {background-color:gold;}
    25% {background-color:#fd9702;}
	50% {background-color:#fd6d02;}
	75% {background-color:#fd9702;}
    100%{background-color:gold;}
}
@-webkit-keyframes flowzred {
    0% {background-color:#e62919;}
    25% {background-color:#e6196e;}
	50% {background-color:#fd6d02;}
	75% {background-color:#e61943;}
    100%{background-color:#e62919;}
}
@keyframes flowzred {
    0% {background-color:#e62919;}
    25% {background-color:#e6196e;}
	50% {background-color:#fd6d02;}
	75% {background-color:#e61943;}
    100%{background-color:#e62919;}
}
@-webkit-keyframes flowzblue {
    0% {background-color:#0aa1f5;}
    25% {background-color:#0bb7f4;}
	50% {background-color:#0ad2f5;}
	75% {background-color:#0bb7f4;}
    100%{background-color:#0aa1f5;}
}
@keyframes flowzblue {
    0% {background-color:#0aa1f5;}
    25% {background-color:#0bb7f4;}
	50% {background-color:#0ad2f5;}
	75% {background-color:#0bb7f4;}
    100%{background-color:#0aa1f5;}
}
@-webkit-keyframes flowzgreen {
    0% {background-color:#25da9b;}
    25% {background-color:#2ad568;}
	50% {background-color:#30d12e;}
	75% {background-color:#2ad568;}
    100%{background-color:#25da9b;}
}
@keyframes flowzgreen {
    0% {background-color:#25da9b;}
    25% {background-color:#2ad568;}
	50% {background-color:#30d12e;}
	75% {background-color:#2ad568;}
    100%{background-color:#25da9b;}
}
/*CAS CONT WRAPS*/

/*Profile CSS*/
.main-navigation .main-nav ul li[class*="current-menu-"] > a:hover{
	color: var(--accent) !important;
}
.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.site-content{
	min-width:0;
}
.container .box{
    width: 100%;
    background: #ecf0f1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* padding: 20px 30px; */
    border-radius: 5px;
	padding:15px;
	box-shadow: 0 2px 2px 0 dimgray;
	border: 3px solid #001262;
    flex-direction: column;
}
.container .box .profile-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.box .quote i{
    margin-top: 10px;
    font-size: 45px;
    color: #17c0eb
}
.box .image img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}
.btns a{
    padding-right: 5px;
}
@media only screen and (min-width: 768px) {
    .box p{
        font-size: 16px;
        padding-left: 15px;
        margin: 0;
    }
    .box .name_job{
        margin-bottom: 10px;
        color: #01002A;
        font-size: 16px;
        padding-left: 15px;
    }
    .box .name_title{
        color: #01002A;
        font-size: 18px;
        font-weight: bold;
        padding-left: 15px;
    }
    .container .box .profile-header{
        display: flex;
        flex-direction: row;
    }
    .container .box .image{
        margin: 10px 0;
        height: 150px;
        min-width: 150px;
        padding: 3px;
        border-radius: 50%;
    }
    .btns img{
        width: 50px;
        height: 50px;
    }
}
@media only screen and (max-width: 768px) {
	.cas-ss-img{
	margin-bottom:14px;
}
    .box p{
        font-size: 16px;
        margin: 0;
        margin-bottom: 15px;
    }
    .box .name_job{
        margin-bottom: 10px;
        color: #01002A;
        font-size: 16px;
    }
    .box .name_title{
        color: #01002A;
        font-size: 18px;
        font-weight: bold;
    }
    .container .box .profile-header {
        display: flex;
        flex-direction: column;
    }
    .container .box .image {
        width: 100px;
        margin: 10px 0;
        height: 100px;
        min-width: 100px;
        padding: 3px;
        border-radius: 50%;
    }
    .btns img{
        width: 40px;
        height: 40px;
    }
}
.btns{
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
@media (max-width:1045px){
    .container .box{
        margin-bottom: 20px;
    }
}
@media (max-width:710px){
    .container .box{
        width: 100%;
    }
}
.overflow-x-auto {
        overflow-x: scroll;
        display: inline-block;
        width: 100%;
    }
    .cas-table {
        width: max-content;
        white-space: nowrap;
			table-layout: fixed;
      width: 100%;
			box-shadow: 0 2px 2px 0 dimgray;
			border: 1px solid #011263;
    }
.cas-table th {
	
    background: #011263;
    font-weight: 800;
	width:100%;
	color:white;
		border: 1px solid #011263;
}
.cas-table td {
	width:100%;
	text-align: center;
    border-left: white;;
    border-right: white;;
}
@media (max-width: 600px) { /* Adjust the screen width threshold as needed */
      .cas-table th:nth-child(1),
      .cas-table td:nth-child(1),
      .cas-table th:nth-child(3),
      .cas-table td:nth-child(3),
	    .cas-table th:nth-child(4),
      .cas-table td:nth-child(4),
      .cas-table th:nth-child(5),
      .cas-table td:nth-child(5) {
        display: none;
      }
    }

/*BETTERBONUS CSS END*/