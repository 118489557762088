/* Notification.css */
.notification-container {
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

    top: 0;
    left: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    pointer-events: none; /* Ensure the container does not interfere with clicks */
  }
  
  .notification {
    top: 0px;
    display: flex;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px;
    align-items: center;
    justify-content: center;
    
    margin: 5px 0;
    padding: 15px;
    border-radius: 5px;
    max-width: 80%;
    text-align: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease, top 0.4s ease; /* Ensure smooth transition for top */
    position: absolute;
    pointer-events: auto; /* Ensure notifications can be interacted with */
  }
  
  .notification.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .notification.success {
    background-color: #d4edda;
    color: #155724;
    border: 2px solid #155724;
  }
  
  .notification.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 2px solid #721c24;
  }
  
  .notification.warning {
    background-color: #fff3cd;
    color: #856404;
    border: 2px solid #856404;
  }
  