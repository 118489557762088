.maincontainer{
  width: 100%;
    display: flex;
}
.main-content{
  display: flex
}
/*Blob Styling*/
.blob{
  padding: 10px 5px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
.blob select{
  width: 100%;
  margin: 0 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bolder;

  height: auto;
    padding: 11px 15px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border:none;
}
.blob textarea{
  width: 100%;
  margin: 0 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bolder;

  height: auto;
    padding: 11px 15px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border:none;
}
/*NAVBAR START*/
/*Navar Container*/
.navparent{
  top: 0;
  position: absolute;
  z-index: 100;
  width: 100%;
}
/*Navbar Outer Nav*/
.topnav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.15);
}
/*Navbar Inner Nav*/
.innernav{
  background-color: #ffffff;
  margin: 0 auto;
  /*max-width: 1200px;*/
}
/*Navbar Element Stylings*/
.topnav a {
  float: left;
  position: relative;
  display: block;
  color: var(--primary-action-color);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  font-weight: 400;
  transition: background-color 0.3s ease;
  z-index: 1;
}
/*Logout Button CSS*/
.topnav a.lasticon{
  float:right;
  color:var(--primary-error-color);
  font-weight: 700;
  transition: background-color 0.3s ease;
  z-index: 1;
}
/*Profile Button CSS*/
.topnav a.profile{
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float:right;
  color:var(--primary-profile-color);
  font-weight: 800;
  transition: background-color 0.3s ease;
  z-index: 1;
}

/*Hover Stylings Start*/
.topnav a:hover {
  color: white;

  -webkit-transition : color 0.3s ease;
  -moz-transition : color 0.3s ease;
  -o-transition : color 0.3s ease;
  transition: color 0.3s ease;
}
.topnav a.lasticon:hover{
  color:white;
}
.topnav a.profile:hover{
  color: white;
}
/*Hover Stylings End*/

/*NAV Service Section CSS (Includes Logout button and Profile)*/
.topnav .navservices{
  display: flex;
    justify-content: flex-end;
}

/*Before Stylings Start*/
.topnav a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--primary-action-color);
  transition: height 0.3s, background-color 0.3s;
  z-index: -1;
}
.topnav a:hover::before {
  height: 100%;
  background-color: var(--primary-action-color);
}
.topnav a.lasticon::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--primary-error-color);
  transition: height 0.3s;
  z-index: -1;
}

.topnav a.lasticon:hover::before {
  height: 100%;
}
.topnav a.profile::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--primary-profile-color);
  transition: height 0.3s;
  z-index: -1;
}
.topnav a.profile:hover::before {
  height: 100%;
}
/*Before Stylings End*/

/*Active Styling Start*/
.topnav a.active {
  background-color: var(--primary-action-color);
  color: white;
}
.topnav a.profile.active {
  margin-left: 0;
  background-color: var(--primary-profile-color);
  color: white;
}

/*Nav Icon CSS*/
.topnav .icon {
  display: none;
}
.topnav a.dash{
  background-color: var(--secondary-offset-color);
  border-bottom: none;
  padding: 14px 10px;
}
.navitem{
  margin-left:0;
}
/*NAV Responsive CSS Start*/
@media screen and (min-width: 728px) {
  .topnav a.profile{
    max-width: 200px;
  }
}
@media screen and (max-width: 727px) {
  .topnav {
    background-color: var(--secondary-offset-color);
}
.topnav .dash{
  background-color: var(--secondary-offset-color);
  color:white;
}
.navitem{
  margin-left:0;
}
.innernav {
border:0;
}
.topnav{
  border-bottom: 2px solid var(--primary-action-color);
}
.topnav a.icon:hover::before {
  height: 100%;
  background-color: var(--secondary-offset-color);
}
.topnav a:hover::before {
  height: 100%;
  background-color: var(--primary-action-color);
}
.topnav a::before {
  background-color: var(--secondary-offset-color);
}
.topnav a.dash {
  background-color: var(--secondary-offset-color);
  border-bottom: none;
  padding: 15px 10px;
}
  .topnav a:not(:first-child) {display: none;}
  .topnav .navservices{
    justify-content: flex-start;
    flex-direction: column;

  }
  .topnav.responsive a.dash{
    border-bottom: 2px solid var(--primary-action-color);
  }
  .topnav.responsive .navservices{
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--secondary-offset-color);
    border-top: 2px solid var(--primary-action-color);
  }
  .topnav .navservices a {
    display: none;
  }
  .topnav.responsive a.profile{
    display: flex;
    color: white;
    width: 75%!important;
    align-items: center;
    justify-content: center;
  }
  .topnav.responsive a.lasticon{
    display: flex !important;
    width: 25%!important;
    color: white;
    border-left: 2px solid var(--primary-action-color);
    justify-content: center;
  }
  .topnav a.icon {
    float: right;
    display: block;
    color:white;
  }
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a.active{
    margin-left: 0;
    margin-right: 0;
  }
  .topnav.responsive a.icon{
    margin-left: 0;
    margin-right: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    width: auto;
    text-align: left;
  }
  .lasticon{
    float: left;
  }
  .lasticon.profile{
    float: left;
  }
}
/*NAV Responsive CSS End*/

/*NAVBAR END*/
/*UNIVERSAL STYLINGS END*/

/*AUTH PAGES STYLES START*/
/*Auth Pages Parent Container*/
.dashpanel{
  width: 100%;
  margin-left: 78px;
}
.auth-page {
  height: 100vh;
  display: grid;
  place-items: center;
}

/*Input Textbox Styles*/
.form input {
  width: 100%;
  margin: 0 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bolder;

  height: auto;
    padding: 11px 15px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border:none;
}
.form input:focus{
  outline-color: var(--primary-action-color);
  outline-width: 2px;
}
.submitform {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bolder;

  height: auto;
    padding: 5px 10px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: black;
    line-height: 1.334;
    font-weight: 500;
    border:none;
}
.submitform:focus{
  outline-color: var(--primary-action-color);
  outline-width: 2px;
}
/*Messages Stylings*/
.msg-success{
  background: var(--secondary-success-color);
    color: black;
    padding: 15px 20px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    font-weight: bolder;
    border-radius: 5px;
}
.msg-error{
  margin: 15px 0 0;
  background: var(--secondary-error-color);
    color: black;
    padding: 15px 20px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    font-weight: bolder;
    border-radius: 5px;
}

/*Form Styling*/
.form {
  margin-left: 15px;
    margin-right: 15px;
	border-radius: 10px;
  position: relative;
  z-index: 1;
  max-width: 360px;
  text-align: center;
  padding: 15px 30px;
}
.form h1{
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bolder;
}
.form button {
  text-transform: uppercase;
  outline: 0;
  border-radius: 5px;
  background: var(--primary-action-color);
  width: 100%;
  border: 0;
  padding: 15px;
  color: white;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: bolder;
}
.buttonsetcontainer{
  display: flex;
}
.buttonsetcontainer.hrefpopup{
  display: flex;
  justify-content: center;
}
.sitemanagerbutton {
  text-transform: uppercase;
    outline: 0;
    border-radius: 5px;
    background: var(--primary-action-color);
    /* width: 100%; */
    margin: 15px;
    border: 0;
    padding: 15px;
    color: white;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
    font-weight: bolder;
}
.sitemanagerbutton.empty {
  margin:5px 0 0 0;
}
.sitemanagerbutton.add{
background: var(--primary-success-color);
margin: 15px;
    padding: 10px;
}
.sitemanagerbutton.delete{
  background: var(--primary-error-color);
  margin: 5px;
    padding: 5px;
}
.sitemanagerbutton.addhref{
  background: var(--primary-success-color);
  margin: 5px;
    padding: 5px;
  }
.sitemanagerbutton.deletehref{
  background: var(--primary-error-color);
  margin: 5px;
    padding: 10px;
  }
.sitemanagerbutton.deletehref.minor{
  margin: 15px !important;
}
.sitemanagerbutton.selected{
  background: var(--primary-profile-color);
}
.sitemanagerbutton.selectedhref{
  background: var(--primary-profile-color);
  margin: 5px;
    padding: 10px;
}
.sitemanagerdeletebutton {
    background: var(--primary-error-color) !important;
    margin-top: 5px;
}
.sitemanagerpagecontainer {
  display: flex;
  align-items: center;
}
.form .button-icon{
	margin-left: 5px;
}
.form .logs-icon{
	margin-right: 5px;
}
/*.form button:hover,.form button:active,.form button:focus {
  background: #43A047;
}*/
.form .message {
	font-weight: bolder;
  margin: 15px 0 0;
  color: #000;
  font-size: 12px;
}
.form .message a {
  color: var(--primary-action-color);
  text-decoration: none;
  font-weight: bolder;
  cursor: pointer;
}
.form .register-form {
  display: none;
}
.form-logo{
  margin: 15px 0
}
/*AUTH PAGES STYLES END*/

/*Container Styles*/
.page-container {
	display: flex;
    justify-content: center;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    align-content: center;
  }
.main{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 50px 5px 0 5px;
    justify-content: flex-start;
    justify-content: center;
}
.mainclass{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.codeul li{
  margin-left: 15px;
  margin-bottom: 5px;
}
.copyright{
  font-size: 15px;
}
.copyright-sub{
  font-size: 11px;
}
.new-booking-page{
  padding-top: 100px;
}
.time-slots-page{
  padding-top: 100px;
}
.main .head{
  margin: 10px;
    width: 100%;
}
.main .head h1{
  font-size: 30px;
}
.main .head h2{
  color:var(--text-gray-color);
  font-size: 18px;
  margin-bottom: 5px;
}
.main .head h3{
  color:var(--text-gray-color);
  font-size: 18px;
}
.main .head .usertext{
  color:var(--primary-action-color);
}
.statcontainer{
  display: flex;
    justify-content: space-between;
}
.supportcontainer{
  display: flex;
    justify-content: space-between;
}
.head .code-red{
  text-decoration: underline;
  color:red;
  font-weight: 800;
}
.head .code-orange{
  text-decoration: underline;
  color:rgb(216, 122, 0);
  font-weight: 800;
}
.head .code-yellow{
  text-decoration: underline;
  color:rgb(236, 200, 0);
  font-weight: 800;
}
.stat{
  display: flex;
  padding: 15px 15px;
    justify-content: flex-start;
}
.codeblock{
  display: flex;
    padding: 15px 30px;
    width: 33%;
    justify-content: flex-start;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
.codeblock .code-red{
  color:red;
}
.codeblock .code-orange{
  color:rgb(216, 122, 0);
}
.codeblock .code-yellow{
  color:rgb(236, 200, 0);
}
.codeblock p{
  text-align: center;
  font-size: 12px;
}
.codeblock.blob{
  padding: 10px 5px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

/* ADD SLOT PAGE CSS START*/

.slot-date-container{
  display: flex;
}

/* ADD SLOT PAGE CSS END*/

.sidebar{
  min-height: 100vh;
    width: 78px;
    padding: 6px 0 6px 14px;
    z-index: 99;
    background-color: var(--primary-action-color);
    transition: all .5s ease;
    position: fixed;
    top: 0;
    left: 0;
}

.sidebar.open{
  width: 250px;
}

.sidebar .logo_details{
  height: 60px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo_details .icon{
  opacity: 0;
  transition: all 0.5s ease ;
}



.sidebar .logo_details .logo_name{
  color:var(--color-white);
  font-size: 22px;
  font-weight: 600;
  opacity: 0;
  transition: all .5s ease;
}

.sidebar.open .logo_details .icon,
.sidebar.open .logo_details .logo_name{
  opacity: 1;
}

.sidebar .logo_details .btn{
  position: absolute;
  top:50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all .5s ease;
}

.sidebar.open .logo_details .btn{
  text-align: right;
}

.sidebar i{
  color:var(--color-white);
  height: 60px;
  line-height: 60px;
  min-width: 50px;
  font-size: 25px;
  text-align: center;
}
.sidebar svg{
  color:var(--color-white);
  height: 30px;
  line-height: 60px;
  min-width: 50px;
  font-size: 25px;
  text-align: center;
}

.sidebar .nav-list{
  margin-top: 20px;
  height: 100%;
}

.sidebar li{
  position: relative;
  margin:8px 0;
  background-color: var(--color-second);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  list-style: none;
}
.nav-list li{
  margin-right: 0;
}

.sidebar li .tooltip{
  position: absolute;
  top:-20px;
  left:calc(100% + 15px);
  z-index: 3;
  color:white;
  background-color: var(--color-default);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 14px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
}
.sidebar li:hover .tooltip{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top:50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip{
  display: none;
}

.sidebar input{
  font-size: 15px;
  color: var(--color-white);
  font-weight: 600;
  outline: none;
  height: 35px;
  width: 35px;
  border:none;
  border-radius: 5px;
  background-color: var(--color-second);
  transition: all .5s ease;
}

.sidebar input::placeholder{
  color:var(--color-light)
}

.sidebar.open input{
  width: 100%;
  padding: 0 20px 0 50px;
}

.sidebar .searchsvg{
  position: absolute;
  top:50%;
  left:0;
  transform: translateY(-50%);
  font-size: 22px;
  background-color: var(--color-second);
  color: var(--color-white);
}
.edit-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-overlay.z-ind-25{
  z-index: 25;
}
.edit-overlay .delete-confirmation-popup{
  width: 50%;
    display: flex;
    background-color: white;
    border: 2px solid var(--primary-action-color);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
    flex-direction: column;
    align-items: stretch;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-confirmation-popup {
  display: flex;
    background-color: white;
    border: 2px solid var(--primary-action-color);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
    flex-direction: column;
    align-items: center;
}
.errorStyle{
  outline-color: var(--primary-error-color) !important;
  outline-width: 2px !important;
}
.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  text-decoration: none;
  background-color: var(--primary-action-color);
  position: relative;
  transition: all .5s ease;
  z-index: 12;
}
.sidebar li.activepage a::before{
  transform: scaleX(1);
}
.sidebar li.activepage a::after{
  transform: scaleX(1);
}
.sidebar li a::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  background-color: var(--primary-background-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: transform 0.3s ease-in-out;
  transform-origin: left;
  z-index: -2;
}

.sidebar li.inactivepage a:hover::after{
  transform: scaleX(1);
  color:var(--color-default)
}

.sidebar li a .link_name{
  color:var(--color-white);
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  pointer-events: auto;
  transition: all 0.4s ease;
  pointer-events: none;
  opacity: 0;
}

.sidebar li a:hover{
  /*OSHA*/
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sidebar li.headcta{
  box-shadow: -2px 2px 2px -1px rgba(0,0,0,0.75);
  -webkit-box-shadow: -2px 2px 2px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 2px 2px -1px rgba(0,0,0,0.75);
}
.sidebar li.inactivepage a:hover .link_name,
.sidebar li.inactivepage a:hover svg{
  transition: all 0.5s ease;
  color:var(--color-default);
  margin-left:8px;
}

.sidebar.open li.inactivepage a .link_name{
  opacity: 1;
  pointer-events: auto;
}

.sidebar li i{
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  border-radius: 5px;
}
.sidebar li svg{
  height: 25px;
  line-height: 2  5px;
  font-size: 18px;
  border-radius: 5px;
}

.sidebar li.profile{
  position: fixed;
  border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  height: 60px;
  width: 78px;
  left: 0;
  bottom:-8px;
  padding:10px 14px;
  overflow: hidden;
  transition: all .5s ease;
}

.sidebar.open li.profile{
  width: 250px;
}

.sidebar .profile .profile_details{
  display: flex;
  align-items: center;
  flex-wrap:  nowrap;
}

.sidebar li.inactivepage img{
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .designation{
  font-size: 15px;
  font-weight: 400;
  color:var(--color-white);
  white-space: nowrap;
}

.sidebar li.profile .designation{
  font-size: 12px;
}
.sidebar .profile{
  display: flex;
}
.sidebar .profile #log_out{
  color: var(--primary-error-color);
  position: absolute;
  top:50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--color-second);
  width: 100%;
  height: 35px;
  line-height: 60px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out{
  width: 50px;
  background: none;
}

.sidebar .name a{
  color:var(--color-second);
  transition: all 0.5s ease;
  background: var(--color-second);
}

.sidebar.open .name a{
  height: 23px;
  color:white;
  border-radius: 5px;
  outline: 2px solid var(--color-second);
}
.sidebar.open .name a:hover{
  background: white;
  color:var(--color-second);
  border-radius: 5px;
  outline: 2px solid white;
}

.home-section{
  position: relative;
  background-color: var(--color-body);
  min-height: 100vh;
  top:0;
  left:78px;
  width: calc(100% - 78px);
  transition: all .5s ease;
  z-index: 2;
}

.home-section .text{
  display: inline-block;
  color:var(--color-default);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.sidebar.open ~ .home-section{
  left:250px;
  width: calc(100% - 250px);
}
.sidebar .listed{
  padding:15px 0 15px 0
}
.minimg{
  position: relative;
    display: flex;
    left: 0px;
    width: 35px;
    margin: 0 8px;
    border-radius: 5px;
}
.sidebar .logo_details svg.inactive-icon{
  opacity: 0;
  height: 0;
  width: 0;
  color: var(--color-default) !important; 
  transition: all 0.6s ease;
  pointer-events: none;
}
.sidebar .logo_details svg.active-icon{
  opacity: 1;
  height: 30px;
  width: 30px;
  color:white !important;
  transition: all 0.6s ease;
}

.sidebar li.activepage{
  margin-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sidebar li.activepage a{
  border-top-right-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0;
  
}
.sidebar li.activepage a:hover::after{
  transform: none;
}

.sidebar li.activepage a::before{
  width: 100%;
}
.sidebar li.activepage .link_name{
  color:var(--color-default) !important; 
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sidebar.open li.activepage .link_name{
  opacity: 1;
}
.sidebar li.activepage .icon{
  color:var(--color-default);
  margin-left:8px;
  box-shadow:transparent;
  transition: all .5s ease;
}
.sidebar li .icon{
  margin-left:0;
  box-shadow:transparent;
  transition: all .5s ease;
}
.sidebar .headcta{
  margin-right: 14px;
  border-radius: 5px;;
}
.sidebar li.headcta a{
  border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  margin-right: 14px;
  color:white;
  background-color: var(--color-second);
}
.sidebar.open li.headcta .link_name {
  opacity: 1;
}

/*BUBBLE CSS*/
.bubble{
max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.bubble-container{
  width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.bubble-container h2{
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
.bubble-container h3{
  font-size: 14px !important;
  font-weight: 600 !important;
}
.bubble.hrefsets{
  width: 24%;
  margin:5px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
/*FORM CSS*/
.add-client-form {
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.add-client-form.edit-hreflang{
  border:none;
  box-shadow: none;
  max-width: none;
}
.add-client-form.edit-settings{
  border:none;
  box-shadow: none;
  max-width: none;
  background-color: var(--primary-action-color);
}
.edit-settings label{
  color:white;
}
.add-client-form h3 {
  text-align: center;
  margin-bottom: 20px;
}

.add-client-form input,
.add-client-form select,
.add-client-form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.add-client-form button {
  background-color: var(--color-default);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-client-form button:hover {
  background-color: var(--color-second);
}

.add-client-form p {
  color: #333;
  text-align: center;
  font-weight: bold;
}

/*FORM CSS*/

/*Client Table CSS*/
.client-stats-container {
  width: 60%;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
}

.client-stat-box {
  width: fit-content;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.client-table {
  width: 100%;
  
  border-collapse: collapse;
}

.client-table th, .client-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.client-table th {
  background-color: #f2f2f2;
}

.client-table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.client-table tbody tr:hover {
  background-color: #e9e9e9;
}

.delete-button {
  padding: 5px 10px;
  background-color: #f44336;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c23527;
}

/*Client Table CSS*/

/*Status Message CSS*/
.status-message {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}
.hiddenbuttonstlye{
  text-transform: uppercase;
  outline: 0;
  border-radius: 5px;
  background: #b50e0e;
  /* width: 100%; */
  border: 0;
  padding: 5px;
  margin: 5px;
  color: white;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: bolder;
}
.status-message.restoring {
  background-color: #f7d358; /* yellow */
}

.status-message.success {
  background-color: #3fb618; /* green */
  color: white;
}

.status-message.fail {
  background-color: #e74c3c; /* red */
  color: white;
}
/*Status Message CSS*/

.hreflastupdated{
  font-size: 12px;
}
.supbold{
  font-weight: 900;
}

.warning-text{
  padding: 5px;
  border-radius: 5px;
  background: rgb(255, 237, 158);
  border: 1px solid rgb(255, 174, 0);
  font-size: 10px;
  font-weight: bolder;
  color:#222222;
  width: fit-content;
}

.warning-text a{
    color:var(--color-second);
  text-decoration: none;
}


.green{
  background: green;
}

.orange{
    background: #f17c57fa;
}

.blue{
  background: #3652D9;
}

.rose{
  background:#FF66CC;
}

.denied{
  background:red;
}

.center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.circle {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}
.antipulse{
  animation: antipulse-animation 2s infinite;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    scale: 1;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  50%{
    scale: 1.2;
  }
  100% {
    scale: 1;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

@keyframes antipulse-animation {
  0% {
    scale: 1;
  }
  25%{
    scale: 0.5;
  }
  50%{
    scale: 0.5;
  }
  75%{
    scale: 1.5;
  }
  100% {
    scale: 1;

  }
}

@keyframes shake-animation {
  0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   81% { transform: rotate(5deg); }
   85% { transform: rotate(-5deg); }
   90% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.master{
  color:black;
  float:right;
}

@media (max-width:900px){
  .master{
    display:none
  }
}

.flex-disp{
  display:flex;

    align-items: center;
}

.wiggle-text{
  display: block;
  animation: shake-animation 2s infinite;
}

.flatcat{
  display: flex;
  flex-direction: row;
}
.flatcat select{
  
}
.flatcat .plus{
  display: flex;
  margin: 0 5px 10px 5px;
  color:white;
  padding: 10px;
  font-weight: bolder;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(7, 165, 7);
  align-items: center;
}

.categorytitle{
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 0 !important;
  padding: 5px 8px 5px 8px;
  width: fit-content;
  border-radius: 5px 15px 15px 5px;
  background-color: var(--color-default);
  color:white !important;
  font-weight: 900;
  font-size: 12px !important;
}
.catblock {
  border-top: 1px dashed rgb(200, 200, 200);
}
.catblock:nth-child(1)  {
  border-top: none !important;
}

.settingscontainer{
  display:'flex';
  flex-wrap:'nowrap';
  flex-direction:'column';
}
/* Scrollable container for the update status list */
.hreflang-update-status-list {
  width: 100%;
  list-style-type: none;
  padding: 3px;
  margin: 0;
  max-height: 470px;
  overflow-y: auto;
  border-radius: 15px;
  border: 3px dashed #ddd;
}

.status-item {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.hreflang-url {
  flex-grow: 1;
  font-weight: bold;
  color: #333;
}

.status-icon {
  margin-left: 10px;
  font-size: 14px;
}

.status-message {
  font-size: 12px;
  color: #666;
  margin: 0;
}

/* Styling based on status */
.status-item.pending {
  background-color: #f0f0f0;
  color: #999;
}

.status-item.success {
  background-color: #e6ffe6;
  color: #2e7d32;
}

.status-item.error {
  background-color: #ffebee;
  color: #c62828;
}

.close-button {
  margin-top: 20px;
  background-color: #d32f2f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #b71c1c;
}

/* Remove margin-bottom from the last success item */
.status-item.success:last-of-type {
  margin-bottom: 0;
}