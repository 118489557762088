@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
:root{
  --primary-action-color:#3d0dd3;
  --primary-background-color:#f6f8fb;
  --primary-error-color:rgb(184, 13, 13);
  --primary-profile-color:#ffa003;
  --primary-success-color:rgb(36, 184, 13);

  --secondary-success-color:#e1ffe1;
  --secondary-error-color:#ffdbdb;
  --secondary-offset-color:#2b817f;

/*   border radius */
--radius: 0.2rem;

/*V4*/
  --color-default:/*#004f83*/#3912af;
  --color-second:/*#0067ac*/#37159b;
  --color-white:#fff;
  --color-body:#e4e9f7;
  --color-light:#e0e0e0;

}
*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*{
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
/*UNIVERSAL STYLINGS START*/
body {
  background: var(--primary-background-color  ); /* fallback for old browsers */
  /*font-family: 'Red Hat Display', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 

  /*NEW*/
    overflow-x: hidden;
    display: flex;
    position: relative;
}

.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust this value to position the icon */
  transform: translateY(-100%);
  pointer-events: none; /* Ensure the icon does not interfere with input events */
  color: #ccc; /* Adjust the color to match your design */
}

.input-wrapper input {
  width: 100%;
  padding-left: 30px; /* Add padding to make space for the icon */
  box-sizing: border-box;
}

.form.login{
    width: 100%;
    max-width: 360px;
}
.form.login input{
  padding: 11px 15px 11px 33px;
} 
.auth-page.login{
  width: 100%;
}

/* START of Add Client Form CSS */
.add-client-form input[type="text"],
.add-client-form input[type="date"],
.add-client-form input[type="email"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.add-client-form input[type="checkbox"] {
  margin: 0 5px !important;
    display: flex;
    width: fit-content;
    align-items: center;
}

.add-client-form label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

.add-client-form button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.add-client-form button:hover {
  background-color: #45a049;
}

.add-client-form p {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
  text-align: center;
}

.stat {
  margin-top: 15px;
}
/* END of Add Client Form CSS */