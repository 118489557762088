.slots-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.slot-item {
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: transform 0.2s;
}

.slot-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.play-game-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.play-game-button:hover {
  background-color: #45a049;
}

.new-slot-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.new-slot-form input,
.new-slot-form label {
  margin: 5px 0;
}

.new-slot-form button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.new-slot-form button:hover {
  background-color: #45a049;
}

/* Add this CSS to your styles */
.slot-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.slot-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.slot-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slot-item h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.slot-item p {
  margin: 5px 0;
}

.slot-item a {
  color: #4CAF50;
  text-decoration: none;
  font-weight: bold;
}

.slot-item button {
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  font-size: 1em;
}

.slot-item button:hover {
  background-color: #e68900;
}

.slot-item:hover {
  transform: scale(1.05);
}

.slot-item img{
  border-radius: 6px;
}

.flatcat .plus.plusser {
  margin: 0 0 15px 0;
  display: flex;
  width: 30px;
  height: 28px;
  margin-left: 5px;
  justify-content: center;
}

.sitemanagerbutton.deletehref.addlink{
  margin:0;
}

/* Container holding checkboxes */
.gameType-checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-item {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

/* Hide default checkbox */
.checkbox-item input[type="checkbox"] {
  display: none;
}

/* Custom checkbox style */
.checkbox-item input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
}

/* Checkmark box */
.checkbox-item input[type="checkbox"] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: white;
  transition: background-color 0.2s;
}

/* Checkmark */
.checkbox-item input[type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #4CAF50;
}

/* Hover effect */
.checkbox-item input[type="checkbox"] + label:hover:before {
  border-color: #4CAF50;
}
