/* loader.css */
.neoloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(38 38 38 / 80%); /* Gray overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above other content */
    pointer-events: all; /* Make the overlay catch all pointer events */
}

.neoloader {
    pointer-events: none; /* Allow clicks through the loader itself */
    text-align: center;
}

.neoloader img {
    background-color: white;
    padding: 15px;
    border-radius: 50%;
    width: 120px; /* Set the width of the image */
    height: 120px; /* Set the height of the image */
    animation: spin 1.5s linear infinite; /* Apply the spinning animation */
    transform-origin: center center; /* Ensure rotation around the center */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader-message {
    margin-top: 10px;
    color: white;
    font-size: 16px;
}

.loader-progress {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    margin-top: 10px;
}

.loader-progress-bar {
    height: 10px;
    background-color: #4caf50;
    border-radius: 5px;
    width: 0; /* Initial width */
    transition: width 0.3s ease-in-out;
}
